.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(12, 19, 11);
    color: rgb(255, 255, 255);
    padding: 1rem 6rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo {
    margin-right: 2rem;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

.navbar-links_logo img {
    width: 70px;
    height: 55px;
    margin-right: 4px;
}
.navbar-links_logo h1 {
    display: inline-flex;
    position: absolute;
    
    margin-top: 13px;
    font-size: 20px;
    font-weight: 700;
    color: #2d2d2d;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color: #079407;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
    padding: 0.5rem 1rem;
    color:rgb(0, 0, 0);
    background: #079407;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: 1px solid rgb(0, 0, 0);
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.navbar-sign .sign-in-button {
    background-color: transparent; /* Change to your preferred color */
    color: #06a706;
    border: 1px solid #045304;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-sign .sign-in-button:hover {
    background-color: #079407;
    color: white; /* Change to your preferred hover color */
  }
  
 .navbar-sign .user-icon {
    margin-right: 8px; /* Adjust the spacing as needed */
  }
.navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
    text-align: end;
    background: white;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 1rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 1rem;
    }

    .navbar-sign {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
    }
    .navbar-links_logo h1 {
        
         font-size: 14px;
         
     }
     .navbar-links_logo img {
        width: auto;
        height: 40px;
        
    }
}
