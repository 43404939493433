:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: rgb(90, 70, 141);
  --secondary-dark: rgb(177, 172, 189);
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the full height of the viewport is used */
}
.base_container {
  flex: 1; /* Allows this section to grow and take available space */
}



body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;


  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  background: rgba(242, 242, 242, 1);
}

  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
.swpper img{
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  height: 100vh;
  object-fit: cover;

}
.bgcolor {
  height: 130px;
  background-color: #2587be;
}
@media screen and (max-width:490px){
  .bgcolor {
    height: 100px;
  }
}
.section__padding {
  padding: 1rem 6rem;
}

.section__margin {
  margin: 1rem 6rem;
}
.paddng_form {
  padding: 1rem 4rem;
}


@media screen and (max-width: 700px) {
  .section__padding {
    padding: 0.8rem 4rem;
  }

  .section__margin {
    margin: 2rem;
  }
}



@media screen and (max-width: 550px) {
  .section__padding {
    padding: 0.5rem 2rem;
  }

  .section__margin {
    margin: 0.5rem 2rem;
  }  
}