/* form */
.form-container {
    /* margin-top: -110px; */
    /* min-width: 100%; */
    padding: 20px 26px 17px 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* margin-right: 30px; */
    z-index: 1;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-left: 10px;
}


  .payment-form {
    display: flex;
    flex-direction: column;
  }
  
  .payment-form h2 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: black;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .form-group:last-child {
    margin-right: 0;
  }
  
  .form-group label {
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    color: #9f9f9f;
  }
  
  .form-group select,
  .form-group input {
    padding: 10px;
    width: 100%;
    font-size: 13px;
    border: 2px solid #e1e1e1;
    border-radius: 50px;
    color: #333;
  }
  .button-container {
    display: flex;
    justify-content: center; /* Center the button */
  }
  
  
  .submit:hover {
    background-color: #054105;
    
  }
  .submit {
    height: 50px;
    padding: 10px 30px;
    font-size: 1rem;
    color: #fff;
    background-color: #079407; /* Keep the background color the same */
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 350px;
    text-align: center;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .submit:disabled {
    cursor: not-allowed;
  }
  
  /* Spinner Rotation */
.rotating {
  animation: rotate 1s linear infinite; /* Infinite rotation */
}

@keyframes rotate {
  100% {
    transform: rotate(360deg); /* Full rotation */
  }
}
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
  }
  
  
  p {
    margin: 0;
    font-size: 14px;
  }
  .payment-options button {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    background-color: #079407;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .payment-options button:hover {
    background-color: #054105;
  }
  .buy-now:hover {
    background-color: #054105;
  }
  .payment-summary {
    margin-bottom: 20px;
  }
  
  .payment-summary p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .payment-summary p strong {
    color: #555;
  }
  
  p {
    margin: 0;
  }
  .payment-options button {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    background-color: #079407;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .payment-options button:hover {
    background-color: #054105;
  }
  .buy-now:hover {
    background-color: #054105;
  }
  .payment-summary {
    margin-bottom: 20px;
  }
  
  .payment-summary p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .payment-summary p strong {
    color: #555;
  }
  .arrow {
        /* text-align: center; */
    /* align-items: center; */
    /* justify-content: center; */
    background: rgba(0, 0, 0, 0.05);
    color: #079407;
    padding: 9px;
    top: 0;
    bottom: 0;
    /* height: 24px; */
    /* width: 24px; */
    border-radius: 50%;
    margin-top: 15px;
    font-size: 20px;
  }
  .arrow .vector {
    height: 21px;
    width: 25px;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 3px
  }
  
  .price_unit {
       /* align-items: center; */
    /* justify-content: center; */
    background: rgba(0, 0, 0, 0.05);
    color: #079407;
    width: 95%;
    height: 37px;
    border-radius: 50px;
    padding: 10px;
    margin-left: 10px;

  }
  .price_label {
    margin-left: 10px;
  }
  /* .price_div {
      align-items: center;
      justify-content: center;
     }
      */
  /* Adjustments for dropdownarrow and vector classes */
  .select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  
    border: 2px solid #e1e1e1;
    padding: 8px 35px 8px 10px;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .select-wrapper .dropdownarrow {
    width: 20px;
    height: 20px;
  
    color: #079407;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

/* Modal overlay style */
.modal-overlay-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container styling */
.infomodal {
  background-color: #fff;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  z-index: 1001;
  animation: fadeIn 0.3s ease; /* Smooth fade-in animation */
}
.infomodal h3 {
  padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #333;
}
.exit_button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  border: none;
  padding: 3px;
  background: none;
  color: #333;
}
.exit_button:hover {
  color: #054105;
  font-size: 18px;
}

.info_content {
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  
}
.info_content p {
  margin: 5px ;
  color: #555;
  flex-wrap: nowrap;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 14px;
  
}
.info_content p strong {
 font-size: 14px;
  color: #555;
  justify-content: space-between;
  
}
@media screen and (max-width: 1050px){
    .form-container {
        margin-top: 0px;
    }
    .price_div {
      display: flex;
      flex-direction: column;
    }
    .arrow .vector {
      height: 24px;
      width: 24px;
      text-align: center;
      position: relative;
      transform: rotate(90deg);
    }
    .direction {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .form-row {
      flex-direction: column;
    }
    .select-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    .form-group {
      margin-right: 0px;
    }

}
@media screen and (max-width: 700px) {
    
}

@media screen and (max-width: 550px) {
  .form-container {
    width: 379px;
}
.infomodal {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: auto;
}

}
@media screen and (max-width: 450px){
  .form-container {
    width: 290px;
}
}