.container {
    text-align: center;
    padding: 20px;
    
  }
  
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
   
  }
  
  .title {
    font-size: 25px;
    color: #0fa016;
  }
  
  .logo {
    width: 60px; /* Adjust the size of the logo */
    height: auto;
  }
  
  .receipt-container {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    
    padding: 20px;
    margin-top: 20px;
    width: 50%;
    margin: auto;
    border-radius: 9px;
    background-color: #f9f9f9;
    text-align: justify;
  }
  
  .receipt-content {
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    
  }
  .small-text {
    font-size: 8px; 
    display: block;
   
  }
  .small-text strong {
    font-size: 14px;
  }
  .amount {
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #097a12;
    
  }
  .reciept_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 8.5px;
    font-weight: 700;
    padding-top: 10px;
  }
  .footer-logo {
    width: 18px; /* Adjust size as needed */
    height: auto;
    margin-right: 8px; /* Space between logo and text */
  }
  .text_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
    
  .text_message span {
    font-size: 13px;
    padding: 3px;
  }

  .amount span {
    font-size: 20px;
    text-align: center;
    color: #097a12;
    font-weight: 600;
    border-bottom: none;
  }

  .receipt-content h2 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .receipt-content p {
    margin: 5px ;
    color: #555;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 12px;
   
    
  }
  .receipt-content p strong {
   font-size: 14px;
    color: #555;
  }

  .text_message .date {
    color: #555;
    text-align: center;
    font-size: 11px;
    
  }
  .receipt-content .token {
    background-color: #53fd5b1f;
    color: #09700e;
    padding: 10px 0 10px 0px;
  }
  .receipt-content  .token strong {
    color: #0fa016;
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .action-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0fa016;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #097a12;
  }
  



  @media screen and (max-width: 700px) {
    .receipt-content p {
        font-size: 11px;

    }
    .title-container {
        padding-bottom: 5px;
        margin-bottom: 0;
    }
    .logo {
        width: 40px;
    }
    .title {
        font-size: 15px;
        
      }
      .receipt-container {
        width: 80%;
      }
}
@media screen and (max-width:490px) {
     .receipt-content p {
        font-size: 9px
        
    }
    .receipt-content p strong {
        font-size: 11px;
    }
    .receipt-content .small-text {
        font-size: 8px;
    }
}