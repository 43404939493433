/* Ensures the Swiper carousel spans the entire viewport */
.swiper-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Full viewport height */
    z-index: -1; /* Sends the carousel behind other content */
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-zoom-container {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
/* Carousel image styling */
.carousel-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* Ensures the image covers the entire viewport */
}

/* Overlay styling */
.swiper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 100; /* Sits above the Swiper */
}

/* Content overlay (if any text or buttons are needed on top) */
.content-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 2; /* Sits above the overlay */
    color: white; /* Adjust text color */
    text-align: center;
    font-size: 1.5rem;
    padding: 20px;
}

/* Media query for small screens */
@media screen and (max-width: 1050px) {
    .swiper-zoom-container .carousel-image {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
}
@media screen and (max-width: 450px) {
    /* .swiper-zoom-container .carousel-image {
        object-fit: cover;
    } */
    .swiper-background {
        height: 100vh; /* Ensure full screen height for small devices */
    }

    .carousel-image {
        height: 100vh; /* Adjust for viewport height on small devices */
    }

    .content-overlay {
        font-size: 1rem; /* Smaller font size for better scaling */
    }
}
