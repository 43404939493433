.support-icon {
  position: absolute;
  bottom: 20px;
  right: 40px;
  background-color: #079407;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.support-icon img {
  width: 30px;
  height: 30px;
}

.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  overflow: hidden;
  
}

.contact-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; /* Default layout is row */
  
  
  border-radius: 8px;
  max-width: 800px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  
  
}

.form-column,
.info-column {
  flex: 1;
  background: #fff;
  padding: 40px;
  border-radius: 0px 8px 8px 0px;
  
}
.form-column{
  background: rgb(12, 19, 11);
  color: #079407;
  border-radius: 8px 0px 0px 8px;
  
}
 /* .form-column h2, {
  margin: 20px;
  
  color: #079407;
} */
.form-column,
.info-column h3 {
   color: #079407;
}

.form-column .send  {
  /* margin-top: 0px; */
  border-bottom: 1px solid rgb(18, 41, 14);
  padding-bottom: 10px;
}
 .form-column form label {
  display: block;
  font-size: 14px;
  margin: 10px;
  font-weight: bold;
  color: #079407;
}
.icon-green {
  color: #079407;
}


 .form-column form input,
 .form-column form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid rgba(4, 63, 4, 0.91);
  border-radius: 4px;
  background: transparent;
  color: white;
}
.form-column form textarea {
  height: 100px;
}
.form-column form .btnn{
  display: flex;
}

 .form-column form .submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #0078d7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

 .form-column form .submit-btn:hover {
  background-color: #005fa3;
}

 .info-column .dbox {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.info-column .send {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.info-icon {
  color: #079407; /* Green color for icons */
  margin-right: 10px; /* Gap between icon and text */
  vertical-align: middle; /* Align icon with text */
}

.dbox {
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Ensure icon and text are vertically aligned */
  margin-bottom: 10px; /* Optional: Adds spacing between rows */
}


 .info-column .dbox .icon {
  font-size: 24px;
  margin-right: 10px;
  color: #0078d7;
}

 .info-column .dbox .text {
  flex: 1;
}
.info-column .suggestion{
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}



 .info-column .dbox .text span {
  font-weight: bold;
  color: #079407;
}

 .info-column .dbox .text a {
  color:rgb(0, 0, 0);
  text-decoration: none;
}

 .info-column .dbox .text a:hover {
  text-decoration: underline;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #333;
}
.send-btn:hover {
  background-color: #054105;
  
}
.send-btn {
  height: 50px;
  padding: 10px 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #079407; /* Keep the background color the same */
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 350px;
  text-align: center;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.send-btn:disabled {
  cursor: not-allowed;
}
.success-notification {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



/* Media Query for Mobile */
@media (max-width: 768px) {
  .contact-container{
    flex-direction: column; /* Stack columns vertically */
    max-width: 300px;
   
  }
  .contact-form-overlay {
    overflow-y: scroll;
  }
  .form-column,
.info-column {
  max-width: 300px;
  border-radius: 8px;
 
}
.form-column {
  margin-top: 30px
}
.close-btn {
  
  top: 40px;
  right: 10px;
  color: #079407;
}
.support-icon {
 
  bottom: 15px;
  right: 10px;

}
}