/* Loader Container with green background */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it is above everything */
  }
  
  /* Large Spinner */
  .large-spinner {
    font-size: 100px; /* Large size */
    color: white;
  }
  
  /* Rotating animation */
  .rotating {
    animation: rotate 1s linear infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg); /* Full rotation */
    }
  }
  