.payment {
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_contain {
    display: flex;
    
    align-items: center;
  }
  .content {
    line-height: 30px;
    padding: 20px;
    /* margin-right: 100px; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    /* height: 60%; */
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 7px;
    margin-bottom: 40px;
}
.payname {
    padding-bottom: 30px;
}
.button-container {
    display: flex;
    justify-content: center; /* Center the button */
  }
  
  .cardbtn {
    height: 50px;
    padding: 11px 35px;
    margin-right: 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 194px;
    text-align: center;
  }
  
  .cardbtn:hover {
    background-color: #0056b3;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .modal .button_cls {
    background-color: #27ae60;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
  }
  .success_content {
    background: white;
    padding: 20px;
    line-height: 28.5px;
    border-radius: 10px;
  }
  .success_content h2 {
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .success_content p {
    font-size: 13px;
  }
  .success_content .token_code {
    font-size: 20px;
  }
  .modal-content {
    width: 423px;
    height: 60%;
    background: white;
    padding: 40px;
    line-height: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 30px;
    padding: 5px;
    background: white;
    border-radius: 50%;
    height: 24px;
    cursor: pointer;
  }
  .modal-content h2 {
    margin-bottom: 40px;
  }
  .modal-content-pin {
    width: 300px;
    height: auto;
    background: white;
    padding: 40px;
    line-height: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .modal-content-pin h2 {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .modal-content-pin input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #d0d1db;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .modal-content input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #d0d1db;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .cancel-button {
    padding: 10px 20px;
    margin-left: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.card-number-field {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  input[type="text"] {
    padding-right: 40px;
    
    width: 100%;
   
  }
  .card-type-logo {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right position as needed */
    transform: translateY(-50%);
    max-width: 20px; /* Adjust the max-width to fit the image inside the input field */
    height: auto;
  }

  .submit-button {
    height: 35px;
    padding: 7px;
    margin-right: -1px;
    font-size: 12px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
    text-align: center;
    
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    color: #333;
  }
  
  .payment-status {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    z-index: 4;
  }
  
  .payment-status.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .payment-status.failure {
    background-color: #f8d7da;
    color: #721c24;
  }

  @media screen and (max-width: 1050px) {

  }
  @media screen and (max-width: 700px){
    .content {
        width: 400px;
        font-size: 13px;
    }
    .cardbtn {
        height: 33px;
        width: 112px;
        padding: 7px;
        font-size: 10px;
    }
    .payname {
        font-size: 15px;
    }
    .modal-content {
        width: 325px;
    }
    .modal-content h2 {
        font-size: 15px;
    }
  }
  @media screen and (max-width: 550px){
    .content {
        width: 370px;
    }
  }
  @media screen and (max-width:490px) {
    .content {
        width: 300px;
    }
    .modal-content {
        width: 280px;
    }
  }
  @media screen and (max-width:408px) {
    .content {
        width: 250px;
    }
  }