.footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 27px;
    background: white;
    text-align: center;
    margin-top: 30px;
    height: auto;
    width: 100%;
    position: relative; /* This ensures it is positioned at the bottom */
  }
  
  .footer p {
    font-size: 12px;
  }
  
  .footer a {
    color: black;
  }