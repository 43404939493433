.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main_header h1 {
    font-weight: 900;
    font-size: 45px;
    width: 600px;
    background: linear-gradient(90deg, #18f018, #079407);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: white; /* This won't be visible because of the gradient text */
}

/* .main_content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 20px;
} */

.main_form {
    z-index: 1;
   margin-top: 20px;
    width: 50%;
}
.main_text {
    text-align: justify;
    line-height: 20px;
    max-width: 750px;
    
}
.main_text .mission {
    text-align: center;
    display: block;
    font-size: 18px;
    font-weight: 800;
    padding: 10px;
}
.pro {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
.protection {
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* width: 100%; */
    height: 20px;
    background: white;
    margin-top: 30px;
    padding: 40px;
    border-radius: 100px;
  }
  .pro1,
  .pro2 {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .icon {
    display: block;
    flex-direction: row;
    width: 30px;
    height: 30px;
    color: #0fa016;
    padding: 5px;
  }
  .pro1 .data h3,
  .pro2 .data h3 {
    font-size: 11px;
    font-weight: 800;
    color: #272742;
    line-height: 18px;
  }
  .pro1 .data span,
  .pro2 .data span {
    font-size: 10px;
    font-weight: 400;
    color: #272742;
  }


@media screen and (max-width: 1050px){
    .main_content {
        flex-direction: column-reverse;
        }
    .main_header h1 {
        font-size: 38px;
    }
    .main_form {
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 20px;
    }
}
@media screen and (max-width: 700px) {
    .main_header h1 {
        font-size: 35px;
        width: 400px;
    }
    .main_form {
        margin-top: 20px;
        padding: 10px;
    }
}

@media screen and (max-width: 550px) {
    .main_header h1 {
        font-size: 30px;
        width: 300px;
        margin-top: 8px;
    }
    .main_form {
        padding: 20px 40px;
    }
}
@media screen and (max-width:490px) {
    .main_form {
        padding: 0px 40px;
    }
    .main_header h1 {
        font-size: 26px;
        width: 278px;
        margin-top: 6px;
    }
}
@media screen and (max-width:408px) {
    .protection {
        height: 20px;
        padding: 40px;
        
      }
      
      .icon {
        width: 30px;
        height: 30px;
      }
      .pro1 .data h3,
      .pro2 .data h3 {
        font-size: 8px;
        line-height: 13px;
        
      }
      .pro1 .data span,
      .pro2 .data span {
        font-size: 8px;
        
      }
    
}